.button-primary {
    @apply text-white bg-primary-200 p-2 rounded-8 text-sm font-medium hover:bg-primary-200;
    &:disabled {
        @apply bg-gray-250 p-2 text-gray-75 cursor-not-allowed hover:bg-gray-100;
     
    }
}

.button-primary:hover {
    @apply bg-primary-100;
}

.button-danger {
    @apply text-white bg-red;
}

.button-gradient-xl {
    background: linear-gradient(90deg, #0dcadf 0%, #00b3c7 27.6%, #00a2cf 76.04%);
    border-radius: 1.25rem;
    padding: 0 0.438rem;
    min-height: 2.10rem;
    min-width: 5rem;
    @apply rounded-20 text-15 font-medium text-white outline-none;
}

.button-cancel-xl {
    border-radius: 1.25rem;
    padding: 0 0.438rem;
    min-height: 2.10rem;
    min-width: 5rem;
    @apply rounded-20 text-15 font-medium text-gray-400 outline-none;
}

