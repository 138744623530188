
.input-search {
   @apply w-full rounded-[0.8125rem] border-[0.0938rem] border-gray-100/50 py-[0.375rem] pl-10 text-sm text-gray-500 focus:border-gray-100 focus:ring-transparent
}

.input {
   @apply w-full rounded-[0.8125rem] border-[0.0938rem] border-gray-100/50 py-[0.375rem] pl-3 text-sm text-gray-500 focus:border-gray-100 focus:ring-transparent
}

.form-input:focus {
    @apply outline-none border-transparent;
    box-shadow: 0 0 2px theme('colors.blue');
}

.form-input{
    @apply mt-1 w-full rounded-10 border-none bg-primary-700 text-15 font-normal placeholder:text-opacity-50 focus:border-transparent focus:ring-transparent focus-visible:outline-none placeholder:text-gray-375 text-gray-400
}

.form-input--error {
    @apply border-DEFAULT border-transparent mt-1 w-full rounded-10 bg-error text-15 font-normal placeholder:text-opacity-50 
}
.form-input--error:focus  {
    @apply border-DEFAULT border-red-500 outline-none ring-red-500;
}

.bubble-form-input{
    @apply mt-1 w-full rounded-10 border-none bg-white text-15 font-normal placeholder:text-opacity-50 focus:border-transparent focus:ring-transparent focus-visible:outline-none placeholder:text-gray-375 text-gray-400
}

.input-orange {
    @apply border-DEFAULT border-orange;
}

.input--small {
    @apply py-2;
}

/* Form controls */
.form-control {
    @apply h-9 bg-white border-DEFAULT border-white px-3 text-gray-400 rounded-lg shadow-input;
    line-height: normal;
}

// .form-input {
//     @apply bg-white border border-white px-3 text-80 rounded-lg shadow-input;
// }
.form-input--small {
    @apply h-8 text-sm;
}

.form-textarea {
    @apply bg-white border-textarea border border-DEFAULT rounded-lg font-sans px-2 py-1;
}

.form-textarea--error {
    @apply border-error;
}


.form-input-bordered, .form-select-bordered, .form-input-datepicker {
    @apply border-input;
}

.form-input-datepicker{
    width: 6.5rem;
    @apply h-6 text-sm;
}

.react-datepicker__day{
    font-weight: 300;
}

#grid-3{
    grid-template-columns: 2fr 2fr 3fr;
}

.form-select-bordered {
    @apply appearance-none;
}

/* Sidebar */
.input-sidebar {
    width: calc(100% - theme('width.10'));
}

.input-hidden{
    position:absolute;
    left:-9999px;
}

.checkbox {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: .25rem;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox input:checked ~ .checkmark {
    background-color: #4099de;
    position: relative;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: flex;
    align-items: center;
  }
  
  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' class='block'%3E%3Cpath fill='%23FFF' d='M7.7 9.3c-.23477048-.3130273-.63054226-.46037132-1.01285927-.37708287-.38231702.08328846-.68093514.38190658-.7642236.7642236C5.83962868 10.0694577 5.9869727 10.4652295 6.3 10.7l2 2c.38884351.3811429 1.01115649.3811429 1.4 0l4-4c.3130273-.23477048.4603713-.63054226.3770829-1.01285927-.0832885-.38231702-.3819066-.68093514-.7642236-.7642236C12.9305423 6.83962868 12.5347705 6.9869727 12.3 7.3L9 10.58l-1.3-1.3v.02z'%3E%3C/path%3E%3C/svg%3E");
    z-index: 1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
  }