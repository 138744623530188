@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants; // This is the default v3 tailwind config for screens

/* Vendor & Ignore them from purgecss */
//@import "./vendor/react-table.scss";
@import "./vendor/tippy.scss";
@import "./vendor/react-confirm-alert.scss";
@import "./vendor/react-toastify.scss";

//@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,300i,500,500i,600,700,800&display=swap');

@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,500,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700&display=swap");
/* Components */
@import "./components/buttons.scss";
@import "./components/inputs.scss";

@import "tippy.js/dist/tippy.css"; // optional
@import "tippy.js/themes/light.css";
@import "./vendor/react-datepicker.scss";

/* Bring back default line height from tailwind 0.7 */
html {
    line-height: 1.15;
}

body {
    @apply font-primary;
    background-color: #eef1f4;
}

body {
    font-family: "Montserrat", sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

.fixHeight {
    height:calc(100% - 3.25rem);
}

.login-button-gradient {
    background: linear-gradient(270deg, #00a2cf -4.74%, #00b3c7 75.36%, #f4db96 135.07%);
}

.color-gradient {
    background: linear-gradient(90deg, #0dcadf 0%, #00b3c7 27.6%, #00a2cf 76.04%);
}

.break-word {
    word-break: break-word;
}

/* Utils */
.default-badge {
    height: 50%;
    background-color: #2680c2;
    width: 3px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 2px;
    margin-top: auto;
    margin-bottom: auto;
}
.object-cover {
    object-fit: cover;
}
.break-all {
    word-break: break-all;
}
.scale {
    transition: transform 200ms ease;
    &:hover {
        transform: scale(1.03);
    }
}

.empty-hidden {
    &:empty {
        @apply hidden;
    }
}

.sticky {
    position: sticky;
}

.bg-sidebar {
    background-image: linear-gradient(0deg, #7e8ea1, #3c4655);
}

.bg-white-gradient {
    background: linear-gradient(to right, transparent 0%, transparent 10%, white 90%, white 100%);
}

/* Grid */
.grid {
    display: grid;
}

.column-gap-2 {
    grid-column-gap: 0.5rem;
}

.row-gap-2 {
    grid-row-gap: 0.5rem;
}

.column-gap-4 {
    grid-column-gap: 1rem;
}

.row-gap-4 {
    grid-row-gap: 1rem;
}

.grid-2 {
    grid-template-columns: 1fr 1fr;
}

.grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

/* Slider */
:focus {
    outline: 0;
}

.carousel__slide-focus-ring {
    outline: 0 !important;
}

/* Slick */
.fixedWidth {
    width: calc(100% + 50px);
}

.fixedWidth .slick-list {
    padding-left: 22px;
    left: -22px;
}
.slick-slider,
.slick-track {
    @apply w-full;
    margin: 0 !important;
}

.slick-list {
    @apply flex-1;
    overflow-y: visible !important;
    overflow-x: auto !important;
}
.slick-list::-webkit-scrollbar {
    display: none;
}
.slick-track {
    @apply flex;
    height: auto !important;
    overflow-y: visible !important;
    overflow-x: auto !important;
}

.carrousel .slick-track,
.carrousel .slick-list {
    padding-bottom: 2rem !important;
}

.slick-slide {
    & > div {
        @apply w-full;
    }
}

.slider-size {
    // width: calc(100% - 38px);
    width: 65rem;
}

.scroll::-webkit-scrollbar {
    width: 0.3em;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.image-bubble {
    max-width: 250px;
    height: auto;
}
.doc-bubble {
    width: 60px;
    height: auto;
}
/* to prevent child to be affecter by parent's transforms */
.fixTransform .slick-list,
.fixTransform .slick-track {
    transform: none !important;
}
.break-all {
    word-break: break-all;
}
/* React table*/
.ReactTable {
    border: none !important;
    background-color: white !important;
}

.ReactTable .rt-thead {
    @apply border-t-DEFAULT border-b-2 bg-modal-footer;
    box-shadow: none !important;
    border-color: #e3e7eb;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: none !important;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    padding: 0 0 0 1rem !important;
    margin: 0 !important;
    border-right: none;
}

.ReactTable .rt-tbody .rt-td {
    height: 61px;
    border-right: none !important;
    @apply flex items-center font-sans font-light;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-color: #e3e7eb !important;
}

.ReactTable .rt-tr {
    max-height: 61px;
}

.ReactTable .-pagination {
    box-shadow: none !important;
    border-top-width: 1px !important;
    background: #f6fbff;
}

.ReactTable .-pagination .-btn:disabled div {
    @apply opacity-50;
}

.ReactTable .-pagination .-pageInfo {
    display: none !important;
}

.ReactTable .-pagination .-btn {
    all: unset !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: #f6fbff !important;
}

.-previous {
    text-align: left !important;
    @apply pl-3;
}

.-next {
    text-align: right !important;
    @apply pr-3;
}

.ReactTable .-pagination .select-wrap select {
    -webkit-appearance: button;
    -webkit-border-radius: 10px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url(https://home.edurio.com/wp-content/uploads/2019/02/arrow-down-icon-png-3.png), -webkit-linear-gradient(#fff, #fff 40%, #fff);
    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 15%;
    border: 1px solid #aaa;
    color: #555;
    font-size: inherit;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
}

.CodeMirror,
.CodeMirror .CodeMirror-scroll {
    -webkit-font-smoothing: antialiased;
}

/* Confirm Modal */
.react-confirm-alert-overlay {
    background: rgba(124, 133, 142, 0.75) !important;
}

[class*="fontawesome-"]:before {
    font-family: "fontawesome", sans-serif;
}

.select-search-box {
    position: relative;
    background: #fff;
    border-radius: 25rem;
}

.select-search-box--multiple {
    border-radius: 25rem;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    overflow: hidden;
}

.select-search-box::after {
    font-family: fontawesome;
    content: "\f078";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    line-height: 50px;
    color: #222f3e;
    z-index: 1;
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
    content: "\f002";
}

.select-search-box__icon--disabled::after {
    content: none !important;
}

.select-search-box--input::after {
    display: none !important;
}

.select-search-box__out {
    display: none;
}

.select-search-box__search {
    display: block;
    width: 100%;
    height: 100%;
    border: solid #555;
    background: none;
    outline: none;
    font-size: 16px;
    padding: 0 20px;
    color: #7c858d;
    -webkit-appearance: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 50px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border-color: #bacad6;
}

input.select-search-box__search {
    line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
    box-shadow: none;
}

.select-search-box--input .select-search-box__search {
    cursor: text;
}

.select-search-box__search:focus {
    cursor: text;
}

.select-search-box__search--placeholder {
    font-style: italic;
    font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

.select-search-box input::-moz-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

.select-search-box input:-moz-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.select-search-box__select {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 4px;
    overflow: auto;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    z-index: 10;
    min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
    display: block;
    position: static;
    border-top: 1px solid #eee;
    border-radius: 0;
    box-shadow: none;
}

.select-search-box__select--display {
    display: block;
}

.select-search-box__option {
    font-size: 16px;
    font-weight: 400;
    color: #616b74;
    border-top: 1px solid #eee;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    padding: 5px 7px;
}

.select-search-box__option:first-child {
    border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
    background: #f4f7fa;
}

.select-search-box__option--selected {
    background: #54a0ff;
    color: #fff;
    border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
    background: #2184ff;
    color: #fff;
    border-top-color: #2184ff;
}

.select-search-box__group {
    border-top: 1px solid #eee;
}

.select-search-box__group-header {
    display: none;
}

.sort-desc {
    border-bottom-width: 2px !important;
    border-bottom-color: #a0aec0 !important;
}

.sort-asc {
    border-top-width: 2px !important;
    border-top-color: #a0aec0 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #569ff7 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff !important;
    border-color: #569ff7 !important;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: #569ff7 !important;
}

.resource-select {
    z-index: 9999;
}

.tippy-box[data-theme~="jelou"] {
    background-color: #f2f7fd;
    border-radius: 0.6125rem;
    color: #00b3c7;
}

.tippy-box[data-theme~="jelou"][data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: #f2f7fd !important;
}

.tippy-box[data-theme~="jelou"][data-placement^="right"] > .tippy-arrow::before {
    border-right-color: #f2f7fd !important;
}

.multi-select {
    .dropdown-container {
        @apply border-none;
    }
    .item-renderer {
        @apply flex;
    }
    .dropdown-heading {
        @apply block w-full transition duration-150 ease-in-out;
    }
}

.form {
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.0938rem solid rgba(166, 180, 208, 0.5);
        border-radius: 0.8125rem;
        font-family: inherit;
        font-size: inherit;
        color: #7e819f;
        outline: none;
        background: none;

        &:focus {
            border-color: #a6b4d0ff;
        }
        @apply focus-visible:ring-transparent;
    }

    .inputShop::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
    }

    &__label {
        position: absolute;
        left: 1rem;
        top: 0.6rem;
        padding: 0 0.5rem;
        color: rgba(126, 129, 159, 0.7);
        cursor: text;
        transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
        background-color: white;
    }

    position: relative;
    height: 2.4rem;
    border-radius: 0.8125rem;

    &:hover {
        border-color: #a6b4d0ff !important;
    }

    @apply bg-transparent text-left sm:text-sm;
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
}

.Toastify__toast {
    margin-bottom: 1rem !important;
}

.Toastify__toast-icon {
    display: none !important;
}

.Toastify__toast-container {
    width: 21.875rem !important;
}

.Toastify__toast--success {
    background-color: #ddffde !important;
    border-radius: 0.625rem !important;
    color: #0ca010;
}

.Toastify__toast--error {
    background-color: #fbd5d5 !important;
    border-radius: 0.625rem !important;
    color: #d6806f;
}

.Toastify__progress-bar--success {
    background-color: #ddffde !important;
}

.Toastify__progress-bar--error {
    background-color: #d6806f !important;
}

.Toastify__close-button--success {
    color: #0000 !important;
}

.Toastify__close-button--error {
    color: #0000 !important;
}

.react-tel-input .form-input {
    @apply mt-1 w-full rounded-10 border-none bg-primary-700 text-15 font-normal placeholder:text-opacity-50 focus:border-transparent focus:ring-transparent focus-visible:outline-none #{!important};
}

.react-tel-input .dropdown-input {
    @apply mt-1 rounded-10 border-none bg-primary-700 text-15 font-normal placeholder:text-opacity-50 focus:border-transparent focus:ring-transparent focus-visible:outline-none #{!important};
}

.react-tel-input .flag-dropdown {
    border-radius: 0.625rem 0 0 0.625rem !important;
    @apply border border-opacity-80 bg-primary-700 #{!important};
}

.react-tel-input .selected-flag {
    border-radius: 0.625rem 0 0 0.625rem !important;
    @apply bg-primary-700 #{!important};
}

.bg-menu {
    border-radius: 8px 0px 0px 8px;
    background-color: #ebf9fb;
    color: #00b3c7;
}

.bg-menu-hover {
    &:hover {
        border-radius: 8px 0px 0px 8px;
        background-color: #ebf9fb;
        color: #00b3c7;
    }
}
