@import "~react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
    display: flex !important;
}

.picker-calendar {
    @apply flex border-0 text-sm  font-medium text-gray-400;
}

.picker-calendar .react-datepicker {
    @apply border-0;
}

.picker-calendar .react-datepicker,
.picker-calendar .react-datepicker__month-container {
    @apply w-full;
}

.react-datepicker__navigation {
    top: 0.125rem !important;
}

.picker-calendar .react-datepicker__header {
    @apply border-0 bg-transparent;
}

.picker-calendar .react-datepicker__day-names {
    @apply uppercase;
}

.picker-calendar .react-datepicker__day-name {
    @apply font-normal text-gray-400 text-opacity-60;
}

.picker-calendar .react-datepicker__month {
    @apply mt-0;
}
.picker-calendar .react-datepicker__current-month {
    @apply mb-2 font-bold uppercase text-gray-400 #{!important}; 
}

.picker-calendar .react-datepicker__day {
    @apply font-normal text-gray-400 text-opacity-60;
}

.picker-calendar .react-datepicker__day--selected,
.picker-calendar .react-datepicker__day--in-selecting-range,
.picker-calendar .react-datepicker__day--in-range,
.picker-calendar .react-datepicker__month-text--selected,
.picker-calendar .react-datepicker__month-text--in-selecting-range,
.picker-calendar .react-datepicker__month-text--in-range,
.picker-calendar .react-datepicker__quarter-text--selected,
.picker-calendar .react-datepicker__quarter-text--in-selecting-range,
.picker-calendar .react-datepicker__quarter-text--in-range,
.picker-calendar .react-datepicker__year-text--selected,
.picker-calendar .react-datepicker__year-text--in-selecting-range,
.picker-calendar .react-datepicker__year-text--in-range {
    @apply bg-primary-400 text-primary-200;
}

.picker-calendar .react-datepicker__day--keyboard-selected,
.picker-calendar .react-datepicker__month-text--keyboard-selected,
.picker-calendar .react-datepicker__quarter-text--keyboard-selected,
.picker-calendar .react-datepicker__year-text--keyboard-selected {
    @apply relative rounded-full bg-primary-400 text-primary-200;
}

.picker-calendar .react-datepicker__day--today,
.picker-calendar .react-datepicker__day--range-end {
    @apply bg-primary-200 text-white;
    border-radius: 99999rem !important;
}

.picker-calendar .react-datepicker__day--in-selecting-range {
    @apply bg-primary-600 text-primary-200;
}

.picker-calendar .react-datepicker__day--today:after,
.picker-calendar .react-datepicker__day--range-end:after,
.picker-calendar .react-datepicker__day--in-selecting-range:after {
    @apply hidden;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 99999rem !important;
    @apply bg-primary-600 text-primary-200;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    color: #0dcadf !important;
    background-color: #e7f6f8 !important;
}

.picker-calendar
    .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
    @apply bg-primary-200 text-white;
}

.picker-calendar .react-datepicker__day--selected,
.picker-calendar .react-datepicker__day--in-selecting-range,
.picker-calendar .react-datepicker__day--in-range {
    @apply rounded-full bg-primary-600 text-primary-200;
}

.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
    background-color: #00b3c7 !important;
}
.picker-calendar .react-datepicker__day--range-start {
    @apply bg-primary-200 text-white;
}

.picker-calendar .react-datepicker__day--today {
    border-radius: 99999rem !important;
    @apply border border-primary-200 bg-white text-primary-200;
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--selected:hover {
    @apply rounded-full bg-primary-200 text-white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #00b3c7 !important;
}

.picker-calendar .react-datepicker__day--range-end {
    background-color: #00b3c7 !important;
    color: #fff !important;
}
.react-datepicker {
    @apply flex border-0 font-primary text-sm font-medium text-gray-400;
}

.react-datepicker,
.react-datepicker__month-container {
    @apply w-full;
}

.react-datepicker__header {
    @apply border-0 bg-transparent;
}

.react-datepicker__day-names {
    @apply uppercase;
}

.react-datepicker__day-name {
    @apply font-medium text-gray-400 opacity-50;
}

.react-datepicker__month {
    @apply mt-0;
}

.react-datepicker__day {
    @apply rounded-full text-gray-450 text-opacity-30;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    color: #00b3c7 !important;
    background-color: #e7f6f8 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    color: #00b3c7 !important;
    background-color: #e7f6f8 !important;
    @apply rounded-full;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    @apply relative rounded-full bg-primary-200;
}

.react-datepicker__day--range-start {
    color: #fff !important;
    background-color: #00b3c7 !important;
}

.react-datepicker__day--range-end {
    color: #fff !important;
    background-color: #00b3c7 !important;
}

.react-datepicker__day--keyboard-selected:after,
.react-datepicker__month-text--keyboard-selected:after,
.react-datepicker__quarter-text--keyboard-selected:after,
.react-datepicker__year-text--keyboard-selected:after {
    content: "";
    display: none;
    border-radius: 9999rem;
    position: absolute;
    @apply rounded-full bg-primary-200;
}

.react-datepicker__day--today {
    color: #00b3c7 !important;
    background-color: #e7f6f8 !important;
}

.react-datepicker__day--keyboard-selected {
    color: #00b3c7 !important;
    background-color: #e7f6f8 !important;
}

.react-datepicker__day--today,
.react-datepicker__day--range-end,
.react-datepicker__day--in-selecting-range {
    color: inherit;
    border-radius: 9999rem !important;
    @apply bg-primary-200;
}

.react-datepicker__day--today:after,
.react-datepicker__day--range-end:after,
.react-datepicker__day--in-selecting-range:after {
    @apply hidden;
}

.react-datepicker {
    @apply border-grey-100 #{!important};
}

.react-datepicker,
.react-datepicker__month-container {
    @apply w-full #{!important};
}

.react-datepicker__header {
    @apply border-none bg-transparent #{!important};
}

.react-datepicker__day-names {
    @apply uppercase #{!important};
}

.react-datepicker__day-name {
    @apply font-semibold text-gray-400 #{!important};
}

.react-datepicker__month {
    @apply mt-0 #{!important};
}

.react-datepicker__day {
    @apply text-gray-400 #{!important};
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    @apply bg-primary-200 text-white #{!important};
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    @apply bg-primary-200 text-white #{!important};
}
.react-datepicker__day--today,
.react-datepicker__day--range-end,
.react-datepicker__day--in-selecting-range {
    border-radius: 0.3rem; // !important
    @apply bg-primary-200 text-white #{!important};
}

.react-datepicker__day--today:after,
.react-datepicker__day--range-end:after,
.react-datepicker__day--in-selecting-range:after {
    @apply hidden #{!important};
}
